.TableHeader{
  color: #FFFFFF;
  text-align: center;
}

#Table{
  background: #046138;
  color: #FFFFFF;
}

.TableBody{
  background: #43A047;
  color: white;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 15px 15px 15px;
}

.TableBodyInfo{
  color: white;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin: 0 0 0 0;
  padding: 15px 15px 15px;
}