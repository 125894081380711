.App {
  height: 100%;
  text-align: left;
  top: 0px;
}

.center {
  text-align: center;
}

.padding {
  padding-left: 1.2%;
}

.sidbar {
  top: 100%;
}

.margin {
  margin-bottom: 2%;
}
.page {
  position: relative;
  overflow: auto;
  max-height: 84vh;
}
.timeoutWarning {
  width: 30%;
  padding: 5px;
  margin: 0 auto;
  clear: both;
}
@media only screen and (max-width: 768px) {
  .page {
    position: relative;
    max-height: 72vh;
    overflow: auto;
  }
  .footer {
    left: 0;
  }
}

.header {
  background-color: rgb(66, 69, 89);
}

.footer {
  left: 0;
  right: 0;
  text-align: center;
}

.sr-only {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  top: auto;
  overflow: hidden;
}

.timeoutButton {
  float: right !important;
}