.FormWrapper {
  padding: 1pc;
  border-style: solid;
  border-width: 2px;
  font-family: Arial;
  margin-top: 2pc;
  margin-left: 1pc;
}

.inputBox {
  width: 100%;
}

.Buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding-right: 0;
  width: 100%;
  justify-content: flex-end;
}

.Headder {
  padding-bottom: 0.5pc;
}
.ButtonSubmit {
  text-align: right;
}

.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}

.Rtable.TableWidth {
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .Rtable.TableWidth {
    width: 100%;
  }
}
.Rtable.TableWidth-3of4 {
  width: 75%;
}

.Rtable-cell {
  box-sizing: border-box;
  flex-grow: 2;
  width: 100%;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
  border: solid 3px white;
  color: #1a1a1a;
}
.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
  margin: 0;
}
.Rtable-cell-dark {
  background: #43a047;
}
.Rtable-cell-light {
  background: #72b875;
}
.Rtable-cell-header {
  background: #00693c;
}
.Rtable--4cols > .Rtable-cell {
  width: 25%;
}
.Rtable--5cols > .Rtable-cell {
  width: 20%;
}

.ErrorLable {
  color: #d32f2f;
}
