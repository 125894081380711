
  .FormWrapper {
    padding: 1pc;
    font-family: Arial;
    border-style: solid;
    border-width: 2px;
    margin: 0 0 0 0;
  }

.WrapperWidth {
  width: 30%;
}

.title {
  border-style: solid;
  border-width: 5px;
  border-color: #007584;
  color: white;
  background: #00693C;
  padding: 0.5%;
}

.Buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 1% 0 0 0;
  padding-right: 0;
  width: 100%;
  justify-content: flex-end;
}

.Headder {
  padding-bottom: .5%;
}
.ButtonSubmit {
  margin-right: 1%;
  text-align: right;
}

.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}

.Rtable-cell {
  box-sizing: border-box;
  flex-grow: 2;
  width: 100%;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
  color: #FFFFFF
}
.Rtable-cell.boarderStatus {
  border: solid 1px black;
}
.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
  margin: 0;
}
.Rtable-boarder {
  border-style: solid;
  border-width: 2px;
  padding: 0.5% 0.5% 0.5% 0.5%;
  overflow: hidden;
  display:flex;
  flex-wrap: wrap;
  width: 100%;
}
.Rtable-cell.Rtable-cell--2of4  { width: 50%; }
.Rtable-cell.Rtable-cell--1of4  { width: 25%; }
.Rtable-cell.Rtable-cell--4of4  { width: 100%;}
.Rtable-cell.Rtable-cell--1of7  { width: 14%;}
.Rtable-cell.Rtable-cell--1of5  { width: 20%; }

p.bold {
  font-weight: bold; 
}
.hidden {
  visibility: hidden
}

.ReactTable .rt-thead .rt-th{
  background: #00693C;
  color: white;
}
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  background: #72B875;
  color: white;
}
.ReactTable .rt-tbody .rt-td {
  background: #43A047;
  color: white;

}

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  background:  #00693C;
  color: white;
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: #00000099;
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  cursor: pointer;
  outline: none;
  
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5;
  cursor: default;
  color:white;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  box-sizing: border-box;
  flex-grow: 2;
  width: 100%;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
  border: solid 1px white;
  color: #1A1A1A
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  background:rgb(1, 75, 43);
  color: white;
}
.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: '.';
  background-size: 18px 18px;
  background-repeat: no-repeat; 
  opacity: 0.87;
}

.ReactTable  .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
  content: '.';
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before { 
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;  
  border-bottom: 20px solid #fff;
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;  
  border-top: 20px solid #fff; 
}
